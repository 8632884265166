<!--
 * @Description: 系统通知
 * @Author: heqi
 * @Date: 2022-02-08 11:15:24
 * @LastEditTime: 2023-07-24 15:07:11
 * @LastEditors: tangjz
 * @FilePath: \mfzj\src\views\layout\notification\components\SysNotify.vue
-->
<template>
  <ul>
    <template v-for="item in dataList" :key="item">
      <li class="notice-item">
        <div class="notice-wrapper" @click="getConfig('handle', item.notifiable_type, item)">
          <img :src="getConfig('image', item.notifiable_type, item)" alt="notification-notice-icon">
          <div>
              <el-badge :is-dot="item.read_at === ''"> {{ getConfig('title', item.notifiable_type, item) }} </el-badge>
              <template v-if="item.notifiable_type === 0">
                <p>请查收你的<span v-if="item.type==='month'">月</span><span v-else-if="item.type==='week'">周</span>考勤统计{{item.start_time.split(' ')[0].replace('-', '年').replace('-', '月') + '日'}}-{{item.end_time.split(' ')[0].replace('-', '年').replace('-', '月') + '日'}}</p>
              </template>
              <template v-else>
                <p>{{ getConfig('content', item.notifiable_type, item) }}</p>
              </template>
              <p>{{item.created_at.slice(0, -3).replace('-', '年').replace('-', '月').replace(' ', '日 ')}}</p>
          </div>
        </div>
      </li>
    </template>
  </ul>
</template>
<script>
import { useRouter/* , useRoute */ } from 'vue-router'
import { reactive, toRefs, computed } from 'vue'
import { myToast } from '@/utils/dialogMsg.js'
import { useStore } from 'vuex'
import { getOkrInfo } from '@/apis/okr.js'
import { getSysNoticeText } from '../utils/PrizeConst'
export default {
  props: ['dataList'],
  setup (props, { emit }) {
    const store = useStore()
    const router = useRouter()
    // const route = useRoute()
    const state = reactive({
      depName: '',
      deptId: '',
      selDate: {
        year: '',
        month: ''
      },
      departmentList: computed(() => store.state.okr.departmentList),
      careList: []
    })

    const showNoticeDetail = (item) => {
      const title = item.title ? item.title.trim() : ''
      props.dataList.map(v => {
        if (v.id === item.id && v.read_at === '') {
          v.read_at = '0'
        } else if (v.read_at === '0') {
          v.read_at = +new Date()
        }
      })
      if (item.notifiable_type === 7) { // okr消息详情查看
        getOkrInfo({ id: item.notifiable_id }).then(({ code, data }) => {
          if (code === 0) {
            // console.log(data)
            if (data.id) {
              state.depName = data.dept?.name
              state.deptId = data.dept?.dept_id
              const cycleList = data.cycle.replace(/\s*/g, '').split('年')
              state.selDate.year = cycleList[0]
              cycleList[1].length ? (state.selDate.month = cycleList[1]) : state.selDate.month = ''
              switch (title) {
                case 'OKR创建提醒':
                  // console.log(cycleList)
                  state.deptId = state.departmentList[0].dept_id
                  state.depName = state.departmentList[0].name
                  router.push({ name: 'myokr', params: { id: state.deptId, depName: state.depName, selDate: JSON.stringify(state.selDate) } })
                  break
                case 'OKR更新提醒':
                  router.push({ name: 'myokr', params: { id: state.deptId, depName: state.depName, selDate: JSON.stringify(state.selDate) } })
                  break
                case 'OKR对齐提醒':
                  state.departmentList.forEach(i => {
                    if (i.dept_id === state.deptId) {
                      router.push({ name: 'myokr', params: { id: state.deptId, depName: state.depName, selDate: JSON.stringify(state.selDate), isEdit: true } })
                    } else {
                      myToast({ type: 'error', message: '该账号不存在此部门' })
                    }
                  })
                  break
                case 'OKR自评提醒':
                  router.push({ name: 'myokr', params: { id: state.deptId, depName: state.depName, selDate: JSON.stringify(state.selDate), selfEvaluate: true } })
                  break
                case 'OKR审核提醒':
                  // console.log(1111)
                  router.push({ name: 'evaluation', params: { tabName: '待审批' } })
                  break
                case 'OKR评价提醒':
                  router.push({ name: 'evaluation', params: { tabName: '待评价' } })
                  break
                case 'OKR审核通过':
                case 'OKR审核驳回':
                case 'OKR完成评价':
                  router.push({ name: 'myokr', params: { id: state.deptId, depName: state.depName, selDate: JSON.stringify(state.selDate), setInitDate: true } })
                  break
                default:
                  return false
              }
            } else {
              myToast({ type: 'error', message: 'OKR数据为空' })
            }
          }
        })
      }
      // 左侧未读数减一
      emit('showNoticeDetail', item)
    }
    // 抽奖消息查看
    const getPrizeDetail = (item) => {
      emit('toPrizeDetail', item)
    }

    // 查看员工关怀
    const viewUserCare = (item) => {
      emit('toUserCare', item)
    }

    const getConfig = (state, type, item) => {
      function getHandleClick () {
        switch (type) {
          case 0: // 考勤通知
            return showNoticeDetail(item)
          case 7: // OKR通知
            return showNoticeDetail(item)
          case 8: // 勋章通知
            return showNoticeDetail(item)
          case 9: // 投票通知
            return showNoticeDetail(item)
          case 10: // 抽奖详情
            return getPrizeDetail(item)
          case 11: // 员工关怀
            return viewUserCare(item)
          default:
            return false
        }
      }

      function getTitle () {
        const title = {
          0: '考勤通知',
          7: item.title,
          8: '系统通知',
          9: '投票通知',
          10: '系统通知',
          11: '系统通知'
        }
        if (type === 10 && item.type === '2') { // 处理中奖通知标题
          title[type] = '抽奖通知'
        }
        return title[type]
      }

      function getImage () {
        const noticeImage = require('@/assets/img/notification/notification-notice-icon.svg')
        const okrImage = require('@/assets/img/notification/notification-okr-icon.svg')
        const voteImage = require('@/assets/img/notification/notification-vote-icon.svg')
        const prizeImage = require('@/assets/img/notification/notification-prize-icon.svg')
        const systemImage = require('@/assets/img/notification/notification-system-icon.svg')
        const images = {
          0: noticeImage,
          7: okrImage,
          8: noticeImage,
          9: voteImage,
          10: noticeImage,
          11: systemImage
        }
        if (type === 10 && item.type === '2') { // 处理中奖通知图片
          images[type] = prizeImage
        }
        return images[type]
      }

      function getContent () {
        const contents = {
          7: item.content,
          8: item.title,
          9: item.title,
          10: getSysNoticeText(item),
          11: item.content
        }
        return contents[type]
      }

      switch (state) {
        case 'handle':
          return getHandleClick()
        case 'title':
          return getTitle()
        case 'image':
          return getImage()
        case 'content':
          return getContent()
        default:
          return false
      }
    }
    return {
      ...toRefs(state),
      showNoticeDetail,
      getConfig
    }
  }
}
</script>
<style lang="less">
.notice-item {
  min-height: 118px !important;
  img {
    margin-right: 9px;
    width: 38px;
    height: 38px;
  }
}
.notice-wrapper {
  display: flex;
}
.notice-wrapper:hover {
  cursor: pointer;
}
.notice-wrapper svg {
  margin-right: 14px;
}
.notice-wrapper div p {
  color: @second-text-color !important;
  font-size: 12px;
}
.notice-wrapper div p:first-child {
  color: @default-text-color !important;
  font-size: 14px;
  margin-bottom: 8px;
}
.notice-wrapper div p:nth-child(2) {
  margin: 8px 0;
  line-height: 20px;
}
</style>
<style lang="less" scoped>
.el-badge{
  font-size: 14px;
  :deep(.el-badge__content) {
    right: 0;
    top: 4px;
  }
}
</style>
