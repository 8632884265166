import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { forumPostInfo } from '@/apis/forum'
import { myToast } from '../utils/dialogMsg'
import { getOkrInfo } from '@/apis/okr.js'
export default function (emit) {
  const router = useRouter()
  // 传递不同的数据 查询动态 然后显示 帖子则跳转详情页
  const state = reactive({
    type: null,
    id: null
  })
  function toViewDetail (item) {
    // 区分动态和帖子  评论中动态或帖子以及咨询
    const selDate = {}
    const showComent = true
    const getOkrMesg = () => {
      getOkrInfo({ id: state.id }).then(({ code, data }) => {
        const cycleList = data.cycle.replace(/\s*/g, '').split('年')
        selDate.year = cycleList[0]
        cycleList[1].length ? (selDate.month = cycleList[1]) : selDate.month = ''
        if (code === 0) {
          router.push({ name: 'myokr', params: { id: data.dept.dept_id, depName: data.dept.name, selDate: JSON.stringify(selDate), showComent: showComent } })
        }
      })
    }
    if (item.feed) {
      // 动态
      state.type = 'feed'
      state.id = item.feed.id
    } else if (item.forum) {
      // 帖子
      state.type = 'forum'
      state.id = item.forum.id
    } else if (item.log || item.commentable_type === 4) {
      // 公告
      state.type = 'log'
      state.id = item.log ? item.log.id : item.commentable_id
    } else if (item.comment) {
      // 评论区分 帖子或动态 获取id
      //   item.comment[0]
      if (
        item.commentable_type === 1 ||
        item.comment[0].commentable_type === 1
      ) {
        state.type = 'feed'
        state.id = item.commentable_id || item.comment[0].commentable_id
      }
      if (
        item.commentable_type === 2 ||
        item.comment[0].commentable_type === 2
      ) {
        state.type = 'forum'
        state.id = item.commentable_id || item.comment[0].commentable_id
      }
      if (item.comment[0].commentable_type === 4) {
        state.type = 'log'
        state.id = item.comment[0].commentable_id
      }
      if (item.comment[0].commentable_type === 7) {
        state.id = item.obj_id || item.comment[0].commentable_id
        getOkrMesg(state.id)
      }
    } else if (item.obj_type === 7 || item.comment[0].commentable_type === 7) {
      state.id = item.obj_id || item.comment[0].commentable_id
      getOkrMesg(state.id)
    }
    // 判断是动态还是帖子 跳转对应页面
    // 帖子跳转页面
    if (state.type === 'forum') {
      forumPostInfo(state.id).then((res) => {
        if (res.code === 0) {
          router.push({ name: 'articalDetail', params: { id: state.id } })
        }
        if (res.code === 50001) {
          myToast({ type: 'warning', message: '该帖子已删除' })
        }
      })
    }

    // 动态显示动态隐藏列表
    if (state.type === 'feed' || state.type === 'log') {
      emit('toArticalDetail', state.id, state.type)
    }
  }
  return { toViewDetail }
}
