<!--
 * @Descripttion: 消息通知内容
 * @version: 1.0.0
 * @Author: xup@imyfone.cn
 * @Date: 2022-07-25 14:30:52
 * @LastEditors: tangjz
 * @LastEditTime: 2023-06-02 13:50:52
-->

<template>
  <ul>
    <li v-for="item in dataList" :key="item" @click="toViewDetail(item)" class="cursor-pointer">
        <!-- 用户头像 -->
        <UserAvatar :user="getUserInfo(item)" size="38" fontSize="14" medalSize="58"></UserAvatar>
        <div>
            <div>
                <p>
                    <!-- @我的 提示信息 名字归属评论动态或帖子-->
                    <span>{{ getUserName(item) }}</span>
                    <span class="message-type">{{getMessagesType(item, type)}}</span>
                </p>

                <!--
                    noty-cont 名字下方显示  点赞收藏不显示
                    1. 动态显示 动态内容
                    2. 帖子显示标题
                -->
                <template v-if="type === 'atMe'">

                    <div v-if="item.log" class="noty-cont">
                    <span @click.prevent="toViewDetail(item)" class="active-text-color cursor-pointer">
                        {{item.log.user.name}}的{{item.log.template_name}}
                    </span>
                    </div>

                    <div class="noty-cont" v-else-if="item.self_comment">
                      <span v-html="analyzeEmoji(formatHtml(item.self_comment.body))"></span>
                      <div v-if="item.self_comment.image_url" class="self-comment">
                        <img class="img-width" :src="item.self_comment.image_url">
                      </div>
                    </div>

                    <div v-else class="noty-cont"
                      v-html="(item.comment &&
                        item.comment.length &&
                        item.comment[0].body &&
                        analyzeEmoji(formatHtml(item.comment[0].body))
                    ) || (item.feed && analyzeEmoji(formatHtml(item.feed.feed_content)))">
                    </div>
                </template>

                <template v-if="type === 'recordMe'">
                   <div class="noty-cont" v-html="item.body && analyzeEmoji(formatHtml(item.body))"></div>
                   <div class="noty-cont" v-if="!item.body.length && item.image_url"><img class="img-width" :src="item.image_url"></div>
                </template>
            </div>
            <!-- 时间 -->
            <div class="noty-time">
                {{ formatDate(item.created_at) }}
            </div>
        </div>

        <!-- 右侧显示内容 -->
        <NotificationRightContent :item="item" :type="type"></NotificationRightContent>
    </li>
  </ul>
</template>
<script>
import { formatDate, analyzeEmoji } from '@/utils/filterHtml.js'
import noticeMessage from '@/mixin/noticeMessage.js'
import NotificationRightContent from '@/views/layout/notification/components/NotificationRightContent'
import { computed, reactive, toRefs } from '@vue/reactivity'
export default {
  props: {
    dataList: {
      type: [Array, Object],
      default: () => {}
    },
    type: {
      type: String,
      default: () => ''
    }
  },
  components: { NotificationRightContent },
  setup (props, { emit }) {
    const { toViewDetail } = noticeMessage(emit)

    const state = reactive({
      dataList: computed(() => props.dataList),
      type: computed(() => props.type)
    })

    // 获取用户名称
    const getUserName = (item) => {
      return item.user?.name || item.feed?.user?.name || item.forum?.user?.name
    }

    // 获取头像信息
    const getUserInfo = (item) => {
      // return item.user || (item.feed && item.feed.user) || (item.forum && item.forum.user)
      return item.user || item.feed?.user || item.forum?.user
    }

    // 获取消息类型
    const getMessagesType = (item, type) => {
      let title = ''

      if (item.obj_type === 3 || item.comment) {
        title = '评论'
      } else if (item.forum) {
        title = '帖子'
      } else if (item.log && item.log.template_name) {
        title = item.log.template_name
      } else if (item.obj_type === 7) {
        title = 'OKR'
      } else if (item.feed) {
        if (item.feed?.vote_id) {
          title = '投票'
        } else {
          title = '动态'
        }
      } else {
        title = '评论'
      }
      //   msg.replace(/${title}/, title)
      // console.log(type)
      switch (type) {
        case 'atMe':
          return `在${title}@我`
        case 'recordMe':
          return `回复了我的${title}`
        case 'collected':
          return `收藏了我的${title}`
        case 'praised':
          return `赞了我的${title}`
      }
    }

    return {
      ...toRefs(state),
      toViewDetail,
      formatDate,
      analyzeEmoji,
      getUserInfo,
      getUserName,
      getMessagesType
    }
  }
}
</script>
<style lang="less" scoped>
.notification .noty-list li .noty-cont .cursor-pointer {
  color: @active-text-color !important;
}
.img-width {
  max-width: 80px;
  border-radius: 3px;
}
.self-comment {
  .img-width {
  max-width: 100px;
  border-radius: 4px;
  margin-top: 4px;
}
}
.user-avatar {
  align-items: flex-start;
}
</style>
