<!--
 * @Descripttion: 右侧内容展示
 * @version: 1.0.0
 * @Author: xup@imyfone.cn
 * @Date: 2022-07-25 13:57:16
 * @LastEditors: tangjz
 * @LastEditTime: 2023-03-28 20:13:47
-->
<template>
      <div :class="[getFileOrText(item)]">

        <!-- 收到的赞 -->
        <template v-if="item.self_comment && type !== 'atMe'">
          <ResourcesCard :data="item.self_comment"></ResourcesCard>
        </template>

        <template v-else-if="item.obj_type === 7">
          <ResourcesCard :data="item"></ResourcesCard>
        </template>

        <!-- 收藏我的投票 -->
        <template v-else-if="item.feed && item.feed.vote_id">
          <ResourcesCard :data="item.feed"></ResourcesCard>
        </template>

        <!-- 右侧显示内容 图片视频类名normal-notify-right 文字类名notify-right -->
        <!-- 动态图片视频优先展示 -->
        <template  v-else-if="item.feed && (item.feed.image_url || item.feed.video_url)">
          <ResourcesCard :data="item.feed"></ResourcesCard>
        </template>

        <template v-else-if="item.comment && item.comment[0]">
          <!-- 动态评论内容展示动态内容 -->
          <ResourcesCard v-if="item.comment[0].feed" :data="item.comment[0].feed"></ResourcesCard>
          <!-- 帖子评论 只显示标题 -->
          <div v-else-if="item.comment[0].forum" v-html="item.comment[0].forum.title"></div>
          <ResourcesCard v-else-if="item.comment[0].body.length > 0" :data="item.comment[0].body"></ResourcesCard>
          <div v-else>[图片]</div>

        </template>

        <!-- 展示日志的 -->
        <template v-else-if="item.log && item.log.user">
          <div> {{item.log.user.name}}的{{item.log.template_name}} </div>
        </template>

        <!-- 没有则帖子标题 或者 动态内容 -->
        <template v-else-if="item.feed">
          <!-- 有分享过来的内容 显示br后方的内容 -->
          <ResourcesCard :data="item.feed"></ResourcesCard>
        </template>

        <template v-else-if="item.forum">
          <div v-html="formatHtml(item.forum.title)"></div>
        </template>
      </div>
</template>

<script>
import { computed, reactive, toRefs } from 'vue'
// import noticeMessage from '@/mixin/noticeMessage.js'
import ResourcesCard from '@/views/layout/notification/components/ResourcesCard'
export default {
  name: 'NotificationRightContent',
  components: { ResourcesCard },
  props: {
    item: {
      type: [Array, Object],
      default: () => {}
    },
    type: {
      type: String,
      default: () => ''
    }
  },
  setup (props, { emit }) {
    const state = reactive({
      item: computed(() => props.item)
    })

    // 或者视频或者图片类型
    const getFileOrText = (item) => {
      // 判断是否是图片/视频
      const justFile = (feed) => {
        return feed?.image_url !== '' || feed?.video_url !== '' || feed?.image_url.split('^')[0] !== ''
      }

      let status = false
      if (item.feed) {
        status = justFile(item.feed)
      } else if (item.comment && item.comment[0]?.feed) {
        status = justFile(item.comment[0].feed)
      }
      // true 为图片/视频展示  false为文字展示
      return status ? 'normal-notify-right' : 'notify-right'
    }

    return {
      ...toRefs(state),
      getFileOrText
    }
  }
}
</script>

<style>

</style>
