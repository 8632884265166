<!--
 * @Author: 何琦 heqi@imyfone.cn
 * @Date: 2023-04-06 15:09:51
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-08-30 16:50:15
 * @FilePath: \sns_web\src\views\layout\notification\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div
    class="notification"
    v-infinite-scroll="load"
    infinite-scroll-immediate="false"
    infinite-scroll-distance="0"
  >
    <!-- 分区 -->
    <template v-if="leftVisible">
      <el-affix :offset="Number(71)" >
        <ul class="noty-area" v-loading="loading">
          <li
            v-for="(item, index) in notyMessage"
            :key="index"
            :class="{ active: index === clickItem ? true : false }"
            @click="changeItem(index)"
            v-show="item.key!=='system' || store.state.userInfo.isWhiteList !== -1"
          >
            <el-badge
              :class="{'is-del': item.showClose, 'is-max': item.count > 99}"
              :value="!item.showClose ? item.count : 'x'"
              :max="99"
              :hidden="!item.count || item.count < 0"
              @click.stop="clearNumData(item)"
              @mouseover="item.count && (item.showClose = true)"
              @mouseout="item.showClose = false"/>
            <div>{{ item.name }}</div>
          </li>
        </ul>
      </el-affix>
    </template>
    <!-- 内容区域 -->
    <el-card class="noty-list" v-if="store.state.messageDel" v-loading="loading">
      <!-- 被回复的 -->
      <template v-if="clickItem !== 4">
        <NotificationItem :dataList="dataList" @toArticalDetail="toArticalDetail" :type="emptyData[clickItem].type"/>
      </template>
      <template v-else>
        <SysNotify :dataList="dataList" @showNoticeDetail="showNoticeDetail" @toPrizeDetail="toPrizeDetail" @toUserCare="toUserCare"></SysNotify>
      </template>

      <empty-data
          v-if="dataList.length == 0 && !loading"
          :image="emptyData[clickItem].image"
          :description="emptyData[clickItem].description"
          size="170"
      ></empty-data>

    </el-card>
    <!-- 动态详情 -->
    <div v-if="!store.state.messageDel">
      <GoBack :backPos="'notification'" />
      <template v-if="detailType==='blob'">
        <div class="content-detail">
          <BlogsList
            :feedList="feedList"
            @collectFeed="updateFeedInfo($event, 2, feedList)"
            @likeFeed="updateFeedInfo($event, 1, feedList)"
            @deleteFeed="deleteBlog"
            :showCommentAuto="true"
          ></BlogsList>
        </div>
      </template>
      <template v-else-if="detailType==='log'">
        <JournalContent :onlyPreview="false"  :journalList="journalList"></JournalContent>
      </template>
      <template v-else-if="detailType === 'notice'">
        <SystemDetail v-bind="{ notifiableId: notifiable_id, noticeTime, noticeDetailData}"></SystemDetail>
      </template>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs, computed, watch, onMounted } from 'vue'
import {
  getMessageReplyList,
  getMessageCollectlist,
  getMessageLiketlist,
  getMessageAtList,
  getNoReadNum,
  getFeedsFeedInfo,
  getMessageList,
  messageClearOne,
  getClearNoReadNum
} from '@/apis/blogs.js'
import { getExceptionCount } from '@/apis/attendance.js'
import { getJournalInfo } from '@/apis/journal.js'
import { getLuckyUsers } from '@/apis/prize.js'
import { useStore } from 'vuex'
import SysNotify from './components/SysNotify.vue'
import SystemDetail from './components/SystemDetail.vue'
import NotificationItem from './components/NotificationItem'
import { myToast } from '@/utils/dialogMsg.js'
import { ElMessage } from 'element-plus'
import JournalContent from '@/views/layout/work/component/JournalContent'
import { useRouter } from 'vue-router'
import eventBus from '@/utils/eventBus.js'
import BlogsList from '@/components/blog/BlogsList.vue'
import { updateFeedInfo } from '@/utils/userCenter.js'

export default {
  name: 'notification',
  props: {
    isLeftVisible: { // 左侧分类是否可见，默认是
      type: Boolean,
      default: true
    }
  },
  components: {
    BlogsList,
    SysNotify,
    SystemDetail,
    JournalContent,
    NotificationItem
  },
  setup (props) {
    const store = useStore()
    const router = useRouter()
    const state = reactive({
      leftVisible: computed(() => props.isLeftVisible),
      refresh: computed(() => store.state.searchRes.refreshValue),
      notyMessage: [
        { key: 'comment', name: '回复我的', count: 0, type: 2, showClose: false },
        { key: 'at', name: '@我的', count: 0, type: 1, showClose: false },
        { key: 'collect', name: '被收藏的', count: 0, type: 4, showClose: false },
        { key: 'like', name: '收到的赞', count: 0, type: 3, showClose: false },
        { key: 'system', name: '系统通知', count: 0, type: 5, showClose: false }
      ],
      emptyData: [
        { image: 'my-response', description: '还没人回复你', type: 'recordMe' },
        { image: 'my-at', description: '还没人@你', type: 'atMe' },
        { image: 'my-collect', description: '还没人收藏你', type: 'collected' },
        { image: 'my-thumbs-up', description: '还没收到过赞', type: 'praised' },
        { image: 'my-system-notice', description: '还没有通知', type: 'system' }
      ],
      feedList: [], // 保存动态详情的信息
      journalList: [], // 保存日志详情的信息
      detailType: '',
      notifiable_id: 0,
      noticeTime: null,
      noticeDetailData: {},
      searchAtMe: computed(() => store.state.searchRes.searchAtMe)
    })
    const loading = ref(false)
    const clickItem = ref(0)
    const dataList = ref([])
    const pages = ref(1)
    function load () {
      if (!store.state.messageDel) {
        return
      }
      if (
        dataList.value.length !== 0 &&
        !store.state.noMore &&
        !store.state.loadMore
      ) {
        store.commit('setLoadMore', true)
        switch (clickItem.value) {
          case 0:
            getNoticeReplyMe(pages.value)
            break
          case 1:
            getNoticeAtMe(pages.value)
            break
          case 2:
            getCollected(pages.value)
            break
          case 3:
            getLikeMessage(pages.value)
            break
          default:
            getSystemNotice(pages.value)
        }
      }
    }

    // 一键清除
    function clearNumData (item) {
      getClearNoReadNum({
        type: item.type
      }).then((res) => {
        if (res.code === 0) {
          store.commit('editMessageCount', Number(store.state.messageCount) - item.count)
          item.count = 0
          item.type === 5 && dataList.value.forEach(i => (i.read_at = '1'))
          // console.log(dataList.value)
        }
      }).catch((err) => {
        console.log(err)
      })
    }
    // 回复我的
    function getNoticeReplyMe (page) {
      if (page === 1) {
        loading.value = true
      }
      getMessageReplyList({
        limit: 10,
        page: page || 1
      })
        .then((res) => {
          afterQuery(res)
        })
        .catch((err) => {
          console.log(err)
          loading.value = false
          store.commit('setLoadMore', false)
        })
    }

    // at我的
    function getNoticeAtMe (page) {
      getMessageAtList({
        limit: 10,
        page: page || 1,
        keyword: state.searchAtMe.keyword,
        official_user_id: state.searchAtMe.id
      })
        .then((res) => {
          afterQuery(res)
        })
        .catch((err) => {
          console.log(err)
          loading.value = false
          store.commit('setLoadMore', false)
        }).finally(() => {
          // 查询结束，隐藏loading效果
          if (!props.isLeftVisible) {
            store.commit('searchRes/setRefreshValue', false)
          }
        })
    }

    // 被收藏的
    function getCollected (page) {
      getMessageCollectlist({
        limit: 10,
        page: page || 1
      })
        .then((res) => {
          afterQuery(res)
        })
        .catch((err) => {
          console.log(err)
          loading.value = false
          store.commit('setLoadMore', false)
        })
    }

    // 被点赞的
    function getLikeMessage (page) {
      getMessageLiketlist({
        limit: 10,
        page: page || 1
      })
        .then((res) => {
          afterQuery(res)
        })
        .catch((err) => {
          console.log(err)
          loading.value = false
          store.commit('setLoadMore', false)
        })
    }

    // 系统通知
    function getSystemNotice (page) {
      getMessageList({
        limit: 10,
        page: page || 1
      }).then((res) => {
        afterQuery(res)
      })
        .catch((err) => {
          console.log(err)
          loading.value = false
          store.commit('setLoadMore', false)
        })
    }

    function afterQuery (res) {
      store.commit('setLoadMore', false)
      if (res.data.from === 1) {
        dataList.value = res.data.data
      } else {
        dataList.value.push(...res.data.data)
      }
      store.dispatch('getMessageCount')
      if (
        (pages.value !== 1 &&
          (res.data.data.length === 0 || res.data.data.length < 10)) ||
        (pages.value === 1 &&
          res.data.data.length < 10 &&
          res.data.data.length !== 0)
      ) {
        store.commit('setNoMore', true)
      }
      pages.value = res.data.current_page + 1
      loading.value = false
    }

    // 首次进入页面
    onMounted(() => {
      if (props.isLeftVisible) { // 正常通过小铃铛进入页面
        init()
        getNoticeReplyMe(pages.value)
      } else { // 搜索@我的进入页面
        // debugger
        changeItem(1)
      }
    })

    // 点击导航切换显示信息
    function changeItem (index) {
      // 触底加载隐藏
      store.commit('setNoMore', false)
      store.commit('setLoadMore', false)
      store.commit('setMessageDel', true)
      clickItem.value = index
      pages.value = 1
      loading.value = true
      // vuex数据更新
      if (index !== 4) {
        store.commit(
          'editMessageCount',
          Number(store.state.messageCount) - state.notyMessage[index].count
        )
        state.notyMessage[index].count = 0
      }
      switch (index) {
        case 0:
          getNoticeReplyMe()
          break
        case 1:
          getNoticeAtMe()
          break
        case 2:
          getCollected()
          break
        case 3:
          getLikeMessage()
          break
        default:
          getSystemNotice()
      }
      dataList.value = []
      document.scrollingElement.scrollTop = 0
    }

    function init () {
      store.commit('setMessageDel', true)
      store.commit('searchRes/setSearchAtMe', {})
      getNoReadNum().then((res) => {
        if (res.code === 0) {
          state.notyMessage.forEach((item) => {
            item.count = res.data[item.key]
          })
          state.notyMessage[0].count = 0
        }
      })
    }
    const updateNoReadNum = (data) => {
      const addOne = [5, 4, 3, 2] // 加一对应notyMessage索引
      const reduceOne = [2, 1, 4, 3] // 减一对应notyMessage索引
      let index = 0
      switch (data.code) {
        case 2: // 评论-点赞-收藏-at 加一
          index = addOne.indexOf(data.data.type)
          index > -1 && (state.notyMessage[index].count += 1)
          break
        case 3: // 系统消息 加一
        case 8: // ORK审核与评价 也属于系统消息
        case 9: // ORK审核通过、驳回与评价 也属于系统消息
          state.notyMessage[4].count += 1
          break
        case 12: // 评论-点赞-收藏-at 减一
          index = reduceOne.indexOf(data.data.type)
          index > -1 && (state.notyMessage[index].count -= 1)
          break
        case 13: // 系统消息 减一
          state.notyMessage[4].count -= 1
          break
        default: return false
      }
    }
    // 绑定更新左侧未读事件
    eventBus.$on('updateNoReadNum', updateNoReadNum)

    // 查看动态详情
    function toArticalDetail (id, type) {
      if (type === 'feed') { // 判断是动态内容还是日志
        state.detailType = 'blob'
        // store.commit('setNoMore', true)
        state.feedList = []
        // 查询动态详情
        getFeedsFeedInfo({ id }).then((res) => {
          if (res.code === 0) {
            state.feedList.push(res.data)
            store.commit('setMessageDel', false)
            setTimeout(() => {
              document.scrollingElement.scrollTop = 0
            }, 50)
            store.commit('setNoMore', false)
          } else {
            myToast({ type: 'warning', message: '内容已被删除' })
          }
          // store.commit('setNoMore', false)
        })
      } else if (type === 'log') { // 日志
        state.detailType = 'log'
        // store.commit('setNoMore', true)
        state.journalList = []
        // 查询日志详情
        getJournalInfo({ id }).then((res) => {
          if (res.code === 0) {
            state.journalList.push(res.data)
            store.commit('setMessageDel', false)
            document.scrollingElement.scrollTop = 0
            store.commit('setNoMore', false)
          } else if (res.code === 2) {
            myToast({ type: 'warning', message: '内容已被删除' })
          }
        })
      }
    }

    // 删除操作
    function deleteBlog (id) {
      const index = state.feedList.findIndex((item) => item.id === id)
      state.feedList.splice(index, 1)
      store.commit('setMessageDel', true)
    }

    // 查看考勤-勋章-投票详情数据
    function showNoticeDetail (data) {
      if (data.read_at === '0') {
        store.commit('editMessageCount', Number(store.state.messageCount) - 1)
        state.notyMessage[4].count = state.notyMessage[4].count - 1
      }
      if (data.notifiable_type === 0) { // 考勤通知详情查看
        state.notifiable_id = data.notifiable_id
        state.detailType = 'notice'
        state.noticeTime = data
        store.commit('setMessageDel', false)
        getExceptionCount({
          id: data.id,
          start_time: data.start_time,
          end_time: data.end_time
        }).then(res => {
          if (res.code === 0) {
            state.noticeDetailData = res.data
          } else {
            ElMessage('error', res.msg)
          }
        })
      } else {
        if (data.notifiable_type === 8) { // 勋章通知详情查看
          router.push({
            name: 'personalCenter',
            query: {
              id: store.state.userInfo.userInfos.id,
              medal_open: 1
            }
          })
        } else if (data.notifiable_type === 9) { // 投票通知详情查看
          toArticalDetail(data.content, 'feed')
        }
        // store.commit('setMessageDel', false)
        messageClearOne({ id: data.id }).then(res => {
          if (res.code !== 0) {
            ElMessage('error', res.msg)
          }
        })
      }
    }

    const methods = {
      toPrizeDetail: (data) => { // 抽奖详情
        getLuckyUsers({ rule_id: data.notifiable_id, batch_id: data.content || undefined }).then(res => {
          state.noticeDetailData = Object.assign(res.data, data)
          store.commit('setMessageDel', false) // 显示详情页面
          state.detailType = 'notice'
          state.notifiable_id = data.notifiable_type
        })
        !data.read_at && messageClearOne({ id: data.id }).then(res => { // 未读数减一
          if (res.code === 0) {
            data.read_at = '0'
            state.notyMessage[4].count = state.notyMessage[4].count - 1
            store.commit('editMessageCount', Number(store.state.messageCount) - 1)
          } else {
            ElMessage('error', res.msg)
          }
        })
      },
      toUserCare: (data) => { // 查看员工关怀
        store.dispatch('getUserCare', {
          param: { care_id: data.notifiable_id },
          isAccept: !data.read_at.length,
          isNextOkr: false
        })

        if (data.read_at) return
        messageClearOne({ id: data.id }).then(res => { // 未读数减一
          if (res.code === 0) {
            data.read_at = '0'
            state.notyMessage[4].count = state.notyMessage[4].count - 1
            store.commit('editMessageCount', Number(store.state.messageCount) - 1)
          } else {
            ElMessage('error', res.msg)
          }
        })
      }
    }
    watch(
      () => state.refresh,
      (newValue, oldValue) => {
        newValue && changeItem(1)
      }
    )
    return {
      ...toRefs(state),
      clickItem,
      changeItem,
      dataList,
      loading,
      load,
      toArticalDetail,
      updateFeedInfo,
      deleteBlog,
      showNoticeDetail,
      store,
      ...methods,
      clearNumData
    }
  }
}
</script>

<style lang="less">
@import '../../../assets/less/notification.less';
.el-badge{
  position: relative;
}
</style>
