export const PrizeSystemState = {
  createPrize: '1', // 创建
  prizeIn: '2', // 中奖
  prizeOver: '3', // 结束
  generatePoster: '4' // 生成海报
}

export const getSysNoticeText = (item) => {
  switch (item.type) {
    case PrizeSystemState.createPrize:
      return `您创建的抽奖活动，链接生成成功！(https://${document.domain}/prize/setting?ruleID=${item.notifiable_id})`
    case PrizeSystemState.prizeIn:
      return '恭喜你中奖啦，快去看看都有哪些奖品吧~'
    case PrizeSystemState.generatePoster: // 抽奖详情
      return `您的中奖名单海报生成成功，快来看看吧！(https://${document.domain}/prize/poster?ruleID=${item.notifiable_id})`
    default:
      return '抽奖结束，快来看看本轮中奖名单吧~'
  }
}

export const getTitle = (type) => {
  switch (type) {
    case PrizeSystemState.createPrize:
      return '您创建的抽奖活动，链接生成成功！'
    case PrizeSystemState.prizeIn:
      return '恭喜你中奖啦，快去看看都有哪些奖品吧~'
    case PrizeSystemState.generatePoster: // 抽奖详情
      return '您的中奖名单海报生成成功!'
    default:
      return '抽奖结束，快来看看本轮中奖名单吧~'
  }
}
