<!--
 * @Descripttion: 右侧资源显示内容
 * @version: 1.0.0
 * @Author: xup@imyfone.cn
 * @Date: 2022-07-25 11:10:42
 * @LastEditors: 何琦 heqi@imyfone.cn
 * @LastEditTime: 2023-03-31 13:43:42
-->
<template>
    <div class="resources-card">
        <img class="img-width" v-if="returnUrl('img')" :src="returnUrl('img')"/>
        <video class="video-width" v-else-if="returnUrl('video')" :src="returnUrl('video')"/>

        <div v-else-if="returnUrl('text')">
            <template v-if="positionTextBr('includes')">
                <span v-if="positionTextBr('split', 1)"
                    v-html="analyzeEmoji(formatHtml(positionTextBr('split', 1)))">
                </span>
                <span v-else v-html="positionTextBr('split', 0)"></span>
            </template>
            <template v-else>
                <span v-html="analyzeEmoji(formatHtml(returnUrl('text')))"></span>
            </template>
        </div>
    </div>
</template>

<script>
import { computed, reactive, toRefs } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { analyzeEmoji } from '@/utils/filterHtml.js'
import { useStore } from 'vuex'
export default {
  name: 'ResourcesCard',
  props: {
    data: {
      type: [Array, Object, String],
      default: () => {}
    }
  },
  setup (props) {
    const store = useStore()
    const state = reactive({
      data: computed(() => props.data)
    })

    onMounted(() => {
    })
    const returnUrl = (type, feed = state.data) => {
      if (feed) {
        if (type === 'img') {
          return feed.image_url?.split('^')[0] && feed.image_url?.split('^')[0] + '?x-oss-process=image/resize,w_650/quality,Q_40'
        } else if (type === 'video') {
          return feed.video_url
        } else if (type === 'text') {
          if (feed.obj_type === 7) return `${feed.user.name}回复了我的OKR`
          if (feed.vote_id) return `${feed.user.id === store.state.userInfo.userInfos.id ? '我' : feed.user.name}创建的投票`
          return feed.feed_content || feed.body || feed
        }
      }
      return false
    }

    // 处理br标签
    const positionTextBr = (type, index) => {
      const text = returnUrl('text')[type]('</br></br>')
      return index ? text[index] : text
    }

    return {
      ...toRefs(state),
      analyzeEmoji,
      returnUrl,
      positionTextBr
    }
  }
}
</script>

<style lang="less" scoped>
.resources-card {
  overflow: hidden;
  text-overflow: ellipsis;
  display:-webkit-box; //作为弹性伸缩盒子模型显示。
  -webkit-box-orient:vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
  -webkit-line-clamp:2; //显示的行
    .img-width {
        width: 80px;
    }
    .video-width {
        width: 120px;
    }
}
</style>
