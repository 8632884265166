<template>
  <div class="notice-detail-wrapper scroll-bar">
    <template v-if="notifiableId === 0">
      <div class="title">{{username}}，请查收你的考勤统计（{{noticeTime.start_time ? (noticeTime.start_time.split(' ')[0].replace('-', '年').replace('-', '月') + '日') : ''}}~{{noticeTime.end_time ? (noticeTime.end_time.split(' ')[0].replace('-', '年').replace('-', '月') + '日') : ''}})</div>
      <table border="1" cellspacing="0">
        <tr>
          <th>迟到</th>
          <th>早退</th>
          <th>缺卡</th>
          <th>旷工</th>
        </tr>
        <tr>
          <td class="attendance-day" @click="goCheckAttendance()">{{noticeDetailData.late_count}}次</td>
          <td class="attendance-day" @click="goCheckAttendance()">{{noticeDetailData.early_count}}次</td>
          <td class="attendance-day" @click="goCheckAttendance()">{{noticeDetailData.lack_card_count}}次</td>
          <td class="attendance-day" @click="goCheckAttendance()">{{noticeDetailData.absenteeism_count}}天</td>
        </tr>
      </table>
    </template>
    <!-- 抽奖相关详情 -->
    <div v-else-if="notifiableId === 10" class="prize-wrapper">
      <div class="prize-title">{{ getTitle(noticeDetailData.type) }}</div>
      <template v-if="noticeDetailData.type === PrizeSystemState.createPrize || noticeDetailData.type === PrizeSystemState.generatePoster">
        <div class="prize-label">活动标题: </div>
        <div>{{ noticeDetailData.title }}</div>
        <div class="prize-label">链接地址: </div>
        <a @click="toPrize(noticeDetailData.type)">{{ getLink(noticeDetailData.type) }}</a>
      </template>

      <template v-else-if="noticeDetailData.type === PrizeSystemState.prizeIn || noticeDetailData.type === PrizeSystemState.prizeOver">
        <div class="prize-label">本轮中奖名单: </div>
        <ul class="prize-in-list">
          <li v-for="item in getPrizeUser.user" :key="item" :class="item.id === userId && 'active'">
            <span>{{ item.name }}</span> <span>{{ item.phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2") }}</span>
          </li>
        </ul>
        <div class="prize-label">中奖奖品: </div>
        <ul class="prize-list">
          <li v-for="item in getPrizeUser.detail" :key="item">
            <div class="prize-title">{{ item.title }}</div>
            <div class="prize-name">{{ item.name }}</div>
            <img :src="item.picture || prizeimg">
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed, onMounted, reactive, toRefs } from 'vue'
import { PrizeSystemState, getTitle } from '../utils/PrizeConst'
import prizeimg from '@/assets/img/prize/default-prizeimg.png'
export default {
  name: 'SystemDetail',
  props: {
    notifiableId: {
      type: Number
    },
    noticeTime: {
      type: Object
    },
    noticeDetailData: {
      type: Object
    }
  },
  setup (props) {
    const router = useRouter()
    const store = useStore()
    const state = reactive({
      userId: computed(() => store.state.userInfo.userInfos.id),
      username: computed(() => store.state.userInfo.userInfos.name),
      getPrizeUser: computed(() => {
        const pagePrizeData = {
          user: [],
          detail: []
        }
        if (props.noticeDetailData.type === PrizeSystemState.prizeIn || props.noticeDetailData.type === PrizeSystemState.prizeOver) {
          const data = []
          Array.isArray(props.noticeDetailData.prize_list) ? props.noticeDetailData.prize_list.forEach(i => {
            data.push(i)
          }) : data.push(...Object.values(props.noticeDetailData.prize_list))
          data.forEach(i => {
            pagePrizeData.user.push(...i.lucky_users.map(i => i.user))
            pagePrizeData.detail.push({
              picture: i.picture,
              title: i.title || '',
              name: i.award_name || ''
            })
          })
        }
        return pagePrizeData || []
      })
    })
    // 考勤统计点击
    function goCheckAttendance () {
      router.push({
        name: 'work',
        params: {
          noticeTime: props.noticeTime.start_time
        }
      })
    }

    const methods = {
      toPrize: (item) => {
        const name = item === PrizeSystemState.createPrize ? 'prizeSetting' : 'prizePoster'
        console.log(name)
        const { href } = router.resolve({ name, query: { ruleID: props.noticeDetailData.notifiable_id } }) // query: { id: res.data }
        window.open(href, '_blank')
      },
      getLink (type) {
        const link = `https://${document.domain}/${type === PrizeSystemState.createPrize ? 'prize/setting' : 'prize/poster'}?ruleID=${props.noticeDetailData.notifiable_id}`
        return link
      }
    }

    onMounted(() => {
      document.body.scrollTop = document.documentElement.scrollTop = 0
    })

    return {
      ...toRefs(state),
      goCheckAttendance,
      PrizeSystemState,
      ...methods,
      getTitle,
      prizeimg
    }
  }
}
</script>

<style lang="less">
.notice-detail-wrapper {
  width: 791px;
  margin-top: 8px;
  font-size: 14px;
  background: #fff;
  min-height: 577px;
  max-height: calc(100vh - 148px);
  border: 1px solid #F1F1F1;
  border-radius: 6px;
  padding: 27px 24px;
  color: @default-text-color;
  overflow: auto;
  .title {
    margin-bottom: 24px
  }
  table {
    width: 100%;
    border-collapse:collapse;
    border: 1px solid #F1F1F1;
  }
  table th {
    font-weight: normal;
    background: #f6f9fa;
  }
  table tr {
    text-align: center;
    height: 60px;
    line-height: 60px;
  }
  .attendance-day{
    cursor: pointer;
  }
  .prize-wrapper {
    font-size: 14px;
    color: #333;
    a {
      color: @active-text-color;
      cursor: pointer;
    }
  }
  .prize-label {
    font-weight: 700;
    margin: 30px 0 16px;
  }
  .prize-list {
    div {
      margin-bottom: 12px;
    }
    img {
      width: 146px;
      height: 146px;
      border-radius: 10px;
    }
  }
  .prize-in-list {
    li {
      height: 20px;
      line-height: 20px;
      margin-bottom: 12px;
      width: 200px;
      display: flex;
      justify-content: space-between;
      &:last-child {
        margin-bottom: 0;
      }
      &.active {
        color: #FF0000;
      }
    }
  }
}
</style>
